import React from "react";
import Hero from "../sections/landing1/Hero";
import Clients from "../sections/landing1/Clients";
import Feature from "../sections/landing1/Feature";
import Content1 from "../sections/landing1/Content1";
import Content2 from "../sections/landing1/Content2";
import Testimonial from "../sections/landing1/Testimonial";
import CTA from "../sections/landing1/CTA";
import PageWrapper from "../components/PageWrapper";
import Features from '../components/features/Features';
import '../assets/styles/global.css';
import Helmet from "react-helmet"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>HotSnail — Mail Redirection, Mail Forwarding & Parcel Forwarding</title>
        <meta name="title" content="HotSnail — Mail Redirection, Mail Forwarding & Parcel Forwarding" />
        <meta name="description" content="Mail Redirection, Mail Scanning, Mail Forwarding & Australian Parcel forwarding from Australia." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hotsnail.com.au/" />
        <meta property="og:title" content="HotSnail — Mail Redirection, Mail Forwarding & Parcel Forwarding" />
        <meta property="og:description" content="Mail Redirection, Mail Scanning, Mail Forwarding & Australian Parcel forwarding from Australia." />
        <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png"></meta>

      </Helmet>

      <PageWrapper footerDark>
        <Hero />
        <Feature />
        {/* <Content1 />
        <Content2 /> */}
        <Testimonial />
        {/* <CTA /> */}
        <Features />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
