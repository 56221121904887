import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";
import { Title, Section, Box } from "../../components/Core";
import featureIcon3 from "../../assets/image/png/feature_icons_3.png";
import featureIcon2 from "../../assets/image/png/feature_icons_2.png";
import featureIcon1 from "../../assets/image/png/feature_icons_1.png";
import featureIcon6 from "../../assets/image/png/feature_icons_6.png";
import featureIcon5 from "../../assets/image/png/feature_icons_5.png";
import featureIcon4 from "../../assets/image/png/feature_icons_4.png";

import styles from './feature.module.css';

const ContentCard = ({
    color = "primary",
    className,
    iconName,
    image,
    message,
    title,
    children,
    ...rest
}) => (
    <Box
        bg="light"
        border="1px solid"
        borderColor="border"
        p="20px"
        borderRadius={10}
        className={`align-items-center ${className} ${styles.outterBox}`}
        {...rest}
        css={`
        transition: all 0.3s ease-out;
        &:hover {
          box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
        }
      `}
    >
        <Box
            size={75}
            color={color}
            fontSize="28px"
            className={`justify-content-center align-items-center ${styles.featureBox}`}
            mr={3}
        >
            <img src={image} alt={title} />
        </Box>

        <Title variant="card" mb={0}>
            <div className="text-center mt-3">
                {title}
            </div>
            <p className="text-center mt-1">
                {message}
            </p>
        </Title>
    </Box>
);


function Features(props) {
    return (
        <>
            <Section bg="ash">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="9">
                            <Box className="text-center" mb={[4, 5]}>
                                <Title color="light">
                                    So many features...
                </Title>
                            </Box>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <ContentCard
                                title="Mail redirection &amp; forwarding"
                                message="Send your snail mail to HotSnail and we can hold and forward the items you wish, when you are ready to receive them."
                                color="primary"
                                image={featureIcon3}
                            />
                        </Col>
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <ContentCard
                                title="Select what gets scanned"
                                message="We can just scan the envelopes for you so you can choose what you want HotSnail open and scan."
                                image={featureIcon2}
                                color="secondary"
                            />
                        </Col>
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <ContentCard
                                title="Easy to use"
                                message="Our intuitive website is designed to make your online mail management easier than ever and HotSnail is optimised it to work across all your devices."
                                image={featureIcon1}
                                color="warning"
                                iconName="icon-phone-charging-3-2"
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <ContentCard
                                title="Mail to email"
                                message="Just want to receive your snail mail letters as email? HotSnail makes this possible."
                                image={featureIcon6}
                                color="secondary"
                            />
                        </Col>
                        <Col lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <ContentCard
                                title="Sort mail into folders"
                                message="HotSnail provides you with a comprehensive mail cloud suitable for organising your snail mail electronically into your own customised folders."
                                image={featureIcon5}
                                color="secondary"
                            />
                        </Col>
                        <Col
                            lg="4"
                            md="6"
                            className="mb-4"
                            data-aos="zoom-in"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="50"
                        >
                            <ContentCard
                                title="No contracts"
                                message="HotSnail will not lock you in or pressure you into long term agreements, use us for a short time or a long time, it is up to you."
                                image={featureIcon4}
                                color="primary"
                            />
                        </Col>
                    </Row>
                </Container>

            </Section>
        </>
    )
}

export default Features;
