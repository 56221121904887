import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, A, Span } from "../../components/Core";

import imgCustomer1 from "../../assets/image/jpeg/l1-customer1.jpg";
import imgCustomer2 from "../../assets/image/jpeg/l1-customer2.jpg";
import imgCustomer3 from "../../assets/image/jpeg/l1-customer3.jpg";

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="border"
    p="25px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
  >
    <Box className={`d-flex justify-content-between`}>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
      <span fontSize="27px">
        <Span color="secondary">
          <i className="fas fa-envelope"></i>
        </Span>
      </span>
    </Box>
    <Text color="dark" mt={4}>
      {children}
    </Text>
  </Box>
);

const Testimonial = () => (
  <>
    {/* <!-- testimonial section --> */}
    <Section>
      <Container>
        <Box pb={[4, null, null, 5]}>
          <Row className="justify-content-center">
            <Col lg="7" xl="6">
              <div className="section-title text-center">
                <Title>
                  Customers are <br className="d-none d-md-block" /> loving
                  HotSnail
                </Title>
              </div>
            </Col>
          </Row>
        </Box>
        <Row className="justify-content-center">
          <Col md="6" lg="4" className="mb-5">
            <ContentCard
              name="Alys"
              company="HotSnail Customer"
            >
              Hi Karl and team, We just wanted to say that we have been using your service for twelve months now and are very happy. We have been receiving everything that we expected to and promptly receive mail if it needs forwarding on to one of the many addresses that we may use. We couldn't be happier and so glad we signed up with Hotsnail. Please feel free to use these comments any way you wish and happy to recommend you.
            </ContentCard>
          </Col>
          <Col md="6" lg="4" className="mb-5">
            <ContentCard
              name="Frances"
              company="HotSnail Customer"
            >
              Dear Mike, Thank you so much the service is one of the best things I have used. It has made travelling so much easier not having to worry about mail and when it will catch up with you. I have told a number of friends about the service and would recommend it to anyone taking a extended break away from home.
            </ContentCard>
          </Col>
          <Col md="6" lg="4" className="mb-5">
            <ContentCard
              name="Andrew"
              company="HotSnail Customer"
            >
              We have finished our travels and will no longer require your excellent service. We will keep our account with you because we will travel again and it is worth using your service even if we go away for a month or so. I must say that we have been very happy with the service that you provide. We have been travelling for over two years (currently in Europe) and your service has enabled us to run our lives remotely! Andrew
            </ContentCard>
          </Col>

          <Col xs="12" className="text-center mt-3">
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <A
                href="/Testimonals"
                fontSize={2}
                fontWeight={500}
                className="d-inline-flex align-items-center"
              >
                <Span color="secondary">
                  <i className="fas fa-comment-alt mr-2"></i>
                  <span>See all testimonials</span>
                </Span>
              </A>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Testimonial;
